import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import HomeLogo from '../../images/HomeLogo.svg';
import '../../styles/globalStyles.css'
import Typed from 'react-typed';
import {Link} from 'react-scroll'
import { useMediaQuery } from 'react-responsive'

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from '../headers/light.js';
import ResponsiveVideoEmbed from '../../helpers/ResponsiveVideoEmbed.js';
import { openPopupWidget } from "react-calendly";




const StyledHeader = styled(Header)`
${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  `;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-0`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
  `;

  const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: '';
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
  `;
  
  export default () => {

    const CustomButton = ({ 
    
      pageSettings={
        backgroundColor: 'ffffff',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: '00a2ff',
        textColor: '4d5055'
      },
      prefill={
        email: '',
        firstName: '',
        lastName: '',
        name: '',
        guests: [
          '',
          ''
        ],
        customAnswers: {
          a1: '',

        }
      },
      utm={
        utmCampaign: 'Web Development',
        utmContet: 'Web & Mobile Development',
        utmMedium: 'Ad',
        utmSource: 'Facebook',
        utmTerm: 'Spring'
      },
      url = 'https://calendly.com/nextnode/15min'
    
    }) => openPopupWidget({ url, prefill, pageSettings, utm });    

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-device-width: 1224px)' })
    
    const navLinks = [
      <ul key={1}>
      
      <Link style={{ padding:'10px', fontWeight:'bold', color: '#425466', display: 'inline', cursor:'pointer' }} to="About" spy={true} smooth={true}>
        About Us
      </Link>
      <Link style={{ padding:'10px', fontWeight:'bold', color: '#425466', display: 'inline', cursor:'pointer' }} to="Services" spy={true} smooth={true}>
        Services
      </Link>
       <Link style={{ padding:'10px', fontWeight:'bold', color: '#425466', display: 'inline', cursor:'pointer' }} to="Technolgies" spy={true} smooth={true}>
      Technolgies
      </Link>
      <Link style={{ padding:'10px', fontWeight:'bold', color: '#425466', display: 'inline', cursor:'pointer' }} to="Portfolios" spy={true} smooth={true}>
       Our Portfolio
      </Link>
      <Link style={{ padding:'10px', fontWeight:'bold', color: '#425466', display: 'inline', cursor:'pointer' }} to="Contact" spy={true} smooth={true}>
        Contact Us
      </Link>
    </ul>,
    <NavLinks key={2}>
      <PrimaryLink onClick={CustomButton} style={{ backgroundColor: '#FF4D6F' }} >
        Hire Us
      </PrimaryLink>
    </NavLinks>,
  ];
 
  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer id='HomeLogo'>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          { isDesktopOrLaptop && <>
            <LeftColumn style={{ minWidth: '100%', minHeight:'50vh' }}>
            <Heading style={{}}>
              <span style={{ color: '#0a2540', paddingTop:'80px', minHeight:'40vh' }}>
                    <Typed
                      strings={
                        [
                          'We have the Best <h1 id="pinkcolor" style="display: inline-block">Developers.</h1>',
                          'Who will Implement your <h1 style="display: inline-block" id=pinkcolor> strategy, </h1> <h1 style="display: inline-block" id=bluecolor> design and&nbsp;<h1 style="display: inline-block" id=yellowcolor>code.</h1>', 
                          'And provide you <h1 style="display: inline-block" id=pinkcolor> the best </h1> <h1 style="display: inline-block" id=bluecolor> innovative &nbsp;<h1 style="display: inline-block" id=yellowcolor>solutions available.</h1>',
                          'So, <h1 style="display: inline-block" id=pinkcolor> let&apos;s start </h1> <h1 style="display: inline-block" id=bluecolor> our &nbsp;<h1 style="display: inline-block" id=yellowcolor>a conversation.</h1>'
                        ]}
                      typeSpeed={50}
                      backSpeed={30}
                      showCursor={false}
                      backDelay={1500}
                      loop
                    />
              </span>
            </Heading>
            <div>
              
              <h1 style={{fontSize:'Large', fontWeight:'bold',  top:"67vh", color:'#425466'}}>
                NextNode is a Web, Mobile and Desktop application development company, that furnish your ideas from scratch to hatch.
              </h1>
            </div>
            <div style={{position:'absolute'}}>
            <PrimaryAction onClick={CustomButton}
              style={{ backgroundColor: '#FF4D6F', color: 'white' }}
            >Schedule a Meeting
            </PrimaryAction>
            </div>
          </LeftColumn>
          </>}
          {isTabletOrMobile && <>
            <LeftColumn style={{ minWidth: '100%', minHeight:'30vh' }}>
            <Heading style={{}}>
              <span style={{ color: '#0a2540', paddingTop:'0px', minHeight:'25vh', paddingLeft:'7%', paddingRight:'7%' }}>
                    <Typed
                      strings={
                        [
                          'We have the Best <h1 id="pinkcolor" style="display: inline-block">Developers.</h1>',
                          'Who will Implement your <h1 style="display: inline-block" id=pinkcolor> strategy, </h1> <h1 style="display: inline-block" id=bluecolor> design and&nbsp;<h1 style="display: inline-block" id=yellowcolor>code.</h1>', 
                          'And provide you <h1 style="display: inline-block" id=pinkcolor> the best </h1> <h1 style="display: inline-block" id=bluecolor> innovative &nbsp;<h1 style="display: inline-block" id=yellowcolor>solutions available.</h1>',
                          'So, <h1 style="display: inline-block" id=pinkcolor> let&apos;s start </h1> <h1 style="display: inline-block" id=bluecolor> our &nbsp;<h1 style="display: inline-block" id=yellowcolor>a conversation.</h1>'
                        ]}
                      typeSpeed={50}
                      backSpeed={30}
                      showCursor={false}
                      backDelay={1500}
                      loop
                    />
              </span>
            </Heading>
            <div>
              <h1 style={{fontSize:'Large', fontWeight:'bold',  top:"60vh", color:'#425466', paddingLeft:'7%', paddingRight:'7%'}}>
                NextNode is a Web, Mobile and Desktop application development company, that furnish your ideas from scratch to hatch.
              </h1>
            </div>
            <div style={{position:'absolute', top:"70vh"}}>
            <PrimaryAction onClick={CustomButton}
              style={{ backgroundColor: '#FF4D6F', color: 'white' }}
            >Schedule a Meeting
            </PrimaryAction>
           </div>
          </LeftColumn>
          </>}
          <RightColumn>
          <div style={{ opacity: '0.0' }}>
            <img src={HomeLogo} alt="Logo Background" />
          </div>
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
