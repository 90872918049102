import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import RubyOnRails from "../../images/rails-logo.svg";
import ReactJS from "../../images/react-logo.svg";
import Gatsby from "../../images/gatsby-logo.svg";
import AWS from "../../images/aws-logo.svg";
import GoogleCloudPlatform from "../../images/google-cloud-platform-logo.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2   rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: RubyOnRails,
      title: "Ruby on Rails",
      description: "For our internal and external products, we chose Ruby on Rails over other technologies and learned a lot from that experience."
    },
    { imageSrc: ReactJS, title: "React Framework",
    description: "Most of our React Developers are senior frontend developers with a lot of real-world development experience." },
    { imageSrc: Gatsby, title: "Gatsby",
    description: "To build E-Commerce sites for our clients, we use Gatsby Framework, that is the best static site generator in our industry. "},
    { imageSrc: AWS, title: "Amazon Web Services",
    description: "We use AWS for the projects, where client needs scalable cloud platform to cater the variable traffic flow. " },
    { imageSrc: GoogleCloudPlatform, title: "Google Cloud Platform",
    description: "To provide seemless infrastructure to our clients, across all google platform products, we use google cloud platform."  },
  ];

  

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading id='Technolgies'> <span style={{color:'#2291FF'}}>Technologies<span style={{color:'#FFB733'}}> &nbsp;We</span><span style={{color:'#FF4D6F'}}> &nbsp;Use</span></span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card style={{backgroundColor:'#F6F9FC'}}>
              <div>
              <img style={{height:'100px', alignContent:'center'}} src={card.imageSrc || defaultCardImage} alt="" />
              </div>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "We never let your business down, by writing un-breakable testing code for websites and application."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
